import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { List, ListProps } from "./list";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { Catalog, search, searchDepartment } from "../../apis/search-catalog";
import { useHistory, useParams } from "react-router-dom";
import { ICategory, getCategorys } from "../../apis/categorys";
import { IDepartment, getDepartments } from "../../apis/department";

const striptags = require("striptags");

export const ListContainer: FC = () => {
  const { accessToken, serverData, portalConnect } = useWebgate();
  let history = useHistory();

  let { category, query, orgao } =
    useParams<{ category: string; query: string; orgao: string }>();

  const [categoryList, setCategoryList] = useState<ICategory[]>(
    serverData?.categoryList || getServerData("categoryList", null)
  );
  const [departmentList, setDepartmentList] = useState<IDepartment[]>(
    serverData?.departmentList || getServerData("departmentList", null)
  );
  const [catalogList, setCatalogList] = useState<any[]>(
    serverData?.catalogList || getServerData("catalogList", [])
  );
  const [searchTextFilter, setSearchTextFilter] = useState<string>(query);
  const [categorySlug, setCategorySlug] = useState<string>(category);
  const [disposition, setDisposition] = useState<"line" | "card">("card");
  const [page, setPage] = useState<number>(0);
  const [resultTotal, setResultTotal] = useState<number>();
  const [allData, setAllData] = useState<Catalog[]>([]);
  const currentPage = useMemo(() => page + 1, [page]);
  const ITEMS_PER_PAGE = 10;

  const fetchAllData = useCallback(async () => {
    try {
      if (orgao) {
        const res = await searchDepartment(accessToken, orgao);
        return res || [];
      }
      const res = await search(accessToken, query, category);
      return res || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [accessToken, query, category, orgao]);


  useEffect(() => {
    fetchAllData()
      .then((res) => {
        setAllData(res);
        setResultTotal(res.length || 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetchAllData]);

  const getCategoryServer = useCallback(() => {
    getCategorys()
      .then(setCategoryList)
      .catch((err) => console.log(err));
  }, []);

  const getDepartmentList = useCallback(() => {
    getDepartments()
      .then(setDepartmentList)
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!categoryList) getCategoryServer();
    if (!departmentList) getDepartmentList();
  }, [getCategoryServer, getDepartmentList, categoryList, departmentList]);

  const openLink = useCallback(
    (link) => {
      if (link.indexOf("/app/") !== -1) {
        portalConnect?.redirectTo(link);
      } else {
        window.open(link, "_blank");
      }
    },
    [portalConnect]
  );

  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`/${categorySlug}/${slug}`);
    },
    [history]
  );

  const onClickSearch = useCallback(() => {
    setPage(0);
    if (categorySlug)
      return history.push(`/list/${categorySlug}/${searchTextFilter || ""}`);

    history.push(`/search/${searchTextFilter}`);
  }, [searchTextFilter, history, categorySlug]);

  const onDepartmentClick = useCallback(
    (departamentSlug: string) => {
      setPage(0);
      history.push(`/list/orgao/${departamentSlug}`);
    },
    [history]
  );

  const onPaginate = useCallback((id: number) => {
    setPage(id - 1);
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  const paginatedData = useMemo(() => {
    if (!allData) {
      return [];
    }

    const start = page * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return allData.slice(start, end).map((catalog) => ({
      ...catalog,
      description: striptags(catalog.description),
    }));
  }, [page, allData]);

  const newsProps: ListProps = {
    list: paginatedData,
    category: category,
    categoryFilter: categorySlug,
    onClick: onClickCard,
    onClickFavourite: () => { },
    onClickOnlineService: openLink,
    searchTextFilter,
    searchText: query,
    onClickSearch,
    onSetSearchValue: setSearchTextFilter,
    categoryList,
    onCategorySelect: setCategorySlug,
    categoryTitle: categoryList?.filter((i) => {
      return i.slug === category;
    })[0]?.name,
    departmentList,
    onDepartmentClick,
    orgaoSlug: orgao,
    orgaoTitle: departmentList?.filter((i) => {
      return i.slug === orgao;
    })[0]?.shortName,
    disposition,
    onDispositionClick: setDisposition,
    onPaginate,
    resultTotal,
    currentPage,
  };

  return <List {...newsProps} />;
};
